import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import {
  Container,
  CardContainer,
  Title,
  StyledButton,
  StyledIcon,
} from "./styles";

import { export_report } from "../../services/dashboard";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import useUserStore from "../../store/useUserStore";

import {
  communication_history_device,
  listCompleteDevices,
  tags_filter,
  device_detail,
  meter_device_install_point_alarm_boundaries_multi,
  install_points_edit_tags,
  install_points_edit,
  listSectorsAlarmeds
} from "../../services/install_points_plus";

import ExportReport from "../../components/ExportReport";
import { Chip } from "primereact/chip";
import { Checkbox } from "primereact/checkbox";
import { PrimeIcons } from "primereact/api";
import { useIntl, FormattedMessage } from "react-intl";
import useTagStore from "../../store/useTagStore";
import ConfigModal from "../../components/Modals/ModalsInstallPointsPlus/ConfigModal";
import ConfirmModal from "../../components/Modals/ModalsInstallPointsPlus/ConfirmModal";
import RowExpansionTemplate from "../../components/RowExpansionTemplate";
import AlarmTypeFilterSerial from "../../components/MultiSelectMemo";
import { debounce } from "lodash";

const finalDate = new Date();

const startDate = new Date(finalDate);
startDate.setMonth(startDate.getMonth() - 12);

const formatDate = (date) => {
  return date.toISOString().split(".")[0] + ".000Z";
};

const finalDateFormatted = formatDate(finalDate);
const startDateFormatted = formatDate(startDate);

const dateStart = new Date(new Date().setMonth(new Date().getMonth() - 12));
const dateEnd = new Date();

export default function InstallPointsPlus() {
  const intl = useIntl();
  const toast = useRef(null);
  const { tags } = useTagStore();
  const { user } = useUserStore();
  const [totalRecords, setTotalRecords] = useState(0);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModalExport, setShowModalExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sectorItems, setSectorItems] = useState([]);
  const [selectedOptionsSectorItems, setSelectedOptionsSectorItems] = useState([]);
  const [historyCommunicationDevice, setHistoryCommunicationDevice] = useState({});
  const [detail, setDetail] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    sector_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: "", matchMode: FilterMatchMode.CONTAINS }],
    },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    serial_number: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    type: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    name_tags: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    last_comm: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [visible, setVisible] = useState(false);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [valueSlider, setValueSlider] = useState([0, 0]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState([]);
  const [sortField, setSortField] = useState("last_communication");
  const [searchSerialNumber, setSearchSerialNumber] = useState('');
  const [devSerialOptions, setDevSerialOptions] = useState([]);
  const [sortOrder, setSortOrder] = useState(-1);
  const [loadingSerialScroll, setLoadingSerialScroll] = useState(false);
  const [serialPage, setSerialPage] = useState(1);
  const [loadInstallPoints, setLoadInstallPoints] = useState(false);

  const [tagList, setTagList] = useState([]);

  const [typeDeviceOptions, setTypeDeviceOptions] = useState([
    { label: intl.formatMessage({ id: "pressure" }), value: "pressure" },
    { label: intl.formatMessage({ id: "flow_title" }), value: "flow" },
  ]);

  useEffect(() => {
    getSectorsAlarmeds(currentPage, 100);
  }, [])

  useEffect(() => {
    getTagList();
    getDevices(1, rowsPerPage);
  }, [tags, filters]);

  const getDevices = async (
    page = 1,
    perPage = 10,
    orderByField = sortField,
    orderBySort = sortOrder
  ) => {
    setLoading(true);
    try {
      const params = {
        page,
        per_page: perPage,
        order_by: `${orderByField} ${orderBySort === 1 ? "ASC" : "DESC"}`,
      };

      if (filters.sector_name?.constraints[0]?.value) {
        params.sector_name = JSON.stringify({
          criteria: 'in',
          values: filters.sector_name.constraints[0].value,
        });
      }

      if (filters.name?.constraints[0]?.value) {
        params.install_name = JSON.stringify({
          criteria: 'contains',
          values: filters.name.constraints[0].value,
        });
      }

      if (filters.serial_number?.constraints[0]?.value) {
        params.serial_number = JSON.stringify({
          criteria: 'in',
          values: filters.serial_number.constraints[0].value,
          field: 'serialNumber',
          type_field: 'text',
        });
      }

      if (filters.type?.constraints[0]?.value) {
        params.type = JSON.stringify({
          criteria: 'equals',
          values: filters.type.constraints[0].value,
        });
      }

      if (filters.name_tags?.constraints[0]?.value) {
        params.tag = JSON.stringify({
          criteria: 'in',
          values: filters.name_tags.constraints[0].value,
        });
      }

      if (filters.last_comm?.constraints[0]?.value) {
        params.last_comm = filters.last_comm.constraints[0].value;
      }

      const response = await listCompleteDevices(params);

      if (!response || !response.items) {
        toast.current.show({
          severity: "error",
          summary: "Atenção",
          detail: "Resposta da API inválida",
          life: 3000,
        });
        return;
      }

      setDevices(response.items);
      setTotalRecords(response.total || 0);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro ao buscar dispositivos",
        detail: error.response?.data?.message || "Erro desconhecido",
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const getSectorsAlarmeds = async (page = 1, perPage = 10) => {
    try {
      const response = await listSectorsAlarmeds({ page, per_page: perPage, only_with_alarms: true });
      const { items } = response;
      setSectorItems(items);
    } catch (error) {

    }
  };

  const getInstallPointsAlarmeds = async (page = 1, perPage = 100, serialNumber) => {

    const formattedSerialNumber = typeof serialNumber === 'string' ? serialNumber.toUpperCase() : serialNumber;
    const serial_number = JSON.stringify({ "criteria": "contains", "values": `${formattedSerialNumber}` });

    try {
      const response = await listCompleteDevices({
        page,
        per_page: perPage,
        serial_number
      });
      return response;
    } catch (error) {
      console.error('Error fetching install points:', error);
      throw error;
    }
  };

  const onPageChange = (event) => {
    const newPage = event.page + 1;
    const newRowsPerPage = event.rows;

    setCurrentPage(newPage);
    setRowsPerPage(newRowsPerPage);

    getDevices(newPage, newRowsPerPage, sortField, sortOrder);
  };

  const handleRowClick = async (event) => {
    const deviceId = event.data.devices[0].id;

    if (historyCommunicationDevice[deviceId]) {
      return;
    }

    const payload = {
      start_date: startDateFormatted,
      final_date: finalDateFormatted,
      device_id: deviceId,
    };

    try {
      const response = await communication_history_device(payload);
      setHistoryCommunicationDevice((prevState) => ({
        ...prevState,
        [deviceId]: response.data.data,
      }));
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Atenção",
        detail: "Falha ao carregar detalhes do dispositivo.",
      });
    }
  };

  const getTagList = async () => {
    try {
      const response = await tags_filter({
        client_id: user.client_id,
      });
      if (Array.isArray(response.data)) {
        setTagList(response.data);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Atenção",
          detail: "API response is not an array",
          life: 3000,
        });
        setTagList([]);
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Atenção",
        detail: error.response.data.message,
        life: 3000,
      });
      setTagList([]);
    }
  };

  const [config, setConfig] = useState({
    filters: {
      sector_name: {
        criteria: filters.sector_name.constraints[0].matchMode,
        values: filters.sector_name.constraints[0].value || "",
      },
      install_name: {
        criteria: filters.name.constraints[0].matchMode,
        values: filters.name.constraints[0].value || "",
      },
      serial_number: {
        criteria: filters.serial_number.constraints[0].matchMode,
        values: filters.serial_number.constraints[0].value || "",
      },
      type: {
        criteria: filters.type.constraints[0].matchMode,
        values: filters.type.constraints[0].value || "",
      },
      name_tags: {
        criteria: filters.name_tags.constraints[0].matchMode,
        values: filters.name_tags.constraints[0].value || "",
      },
      order_by: {
        criteria: filters.last_comm.constraints[0].matchMode,
        values: filters.last_comm.constraints[0].value || "",
      },
    },
    url: export_report,
  });

  const getDeviceDetail = async (data) => {

    if (data.length > 1) {
      setValueSlider([10, 60]);
      setSelectedTag([]);
    }

    if (data.length === 1) {
      try {
        const response = await device_detail({
          device_id: data[0].devices[0].id,
          install_point_id: data[0].id,
        });
        setDetail(response.data[0]);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Atenção",
          detail: error.response.data.message,
          life: 3000,
        });
      }
    }
  };

  useEffect(() => {
    if (detail?.alarm_boundaries) {
      const { lower_limit, upper_limit } = detail.alarm_boundaries;
      setValueSlider([lower_limit, upper_limit]);
    }

    if (detail?.tags_dados?.length) {
      const tags = detail.tags_dados.map((tag) => ({
        client_id: user.client_id,
        id_name: tag.id_name,
        name: tag.name,
        color: tag.color,
        tag_master: null,
      }));

      setSelectedTag(tags);
    } else {
      setSelectedTag([]);
    }

  }, [detail]);

  useEffect(() => {
    if (searchSerialNumber) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await getInstallPointsAlarmeds(1, 100, searchSerialNumber);
          const { items } = response;
          const serialNumbers = items.flatMap(item =>
            item.devices.map(device => device.serial_number)
          );
          const uniqueSerialNumbers = [...new Set(serialNumbers)];
          const devSerialOptions = uniqueSerialNumbers.map(serial => ({
            label: serial,
            value: serial,
          }));
          setDevSerialOptions(devSerialOptions);
        } catch (error) {
          console.error('Error fetching serial numbers:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      setDevSerialOptions([]);
      setIsLoading(false);
    }
  }, [searchSerialNumber]);

  const onLazyLoad = debounce(async (event) => {
    const { first, last } = event;
    const totalItems = devSerialOptions.length;

    if (last >= totalItems - 50 && !loadingSerialScroll) {
      setLoadInstallPoints(true);
      setLoadingSerialScroll(true);
      try {
        const response = await getInstallPointsAlarmeds(serialPage + 1, 100, searchSerialNumber);
        const { items } = response;
        const serialNumbers = items.flatMap(item =>
          item.devices.map(device => device.serial_number)
        );
        const uniqueSerialNumbers = [...new Set(serialNumbers)];
        const newSerialOptions = uniqueSerialNumbers.map(serial => ({
          label: serial,
          value: serial,
        }));

        setDevSerialOptions(prevOptions => [...prevOptions, ...newSerialOptions]);
        setSerialPage(prevPage => prevPage + 1);
      } catch (error) {
        console.error('Error fetching serial numbers:', error);
      } finally {
        setLoadingSerialScroll(false);
        setLoadInstallPoints(false);
      }
    }
  }, 300);

  const setorBodyTemplate = (rowData) => {
    return <span>{rowData.sector_name}</span>;
  };

  const alarmTypeFilterSector = (options) => {
    const sectorOptions = sectorItems.map(sector => ({
      label: sector.name,
      value: sector.name,
    }));

    return (
      <MultiSelect
        value={selectedOptionsSectorItems}
        options={sectorOptions}
        onChange={(e) => {
          setSelectedOptionsSectorItems(e.value);
          options.filterCallback(e.value);
        }}
        placeholder={intl.formatMessage({ id: 'manager_alarms_plus_filter_by_sector' })}
        emptyFilterMessage={intl.formatMessage({ id: 'empty_filter_message' })}
        showClear
        filter
      />
    );
  };

  const nameBodyTemplate = (rowData) => {
    return <span>{rowData.name}</span>;
  };

  const serialNumberBodyTemplate = (rowData) => {
    return <span>{rowData?.devices[0].serial_number}</span>;
  };

  const tipoDispositivoBodyTemplate = (rowData) => {
    if (rowData.devices?.[0]?.type === "pressure") {
      return <span>{intl.formatMessage({ id: "pressure" })}</span>;
    } else if (rowData.devices?.[0]?.type === "flow") {
      return <span>{intl.formatMessage({ id: "flow_tooltip" })}</span>;
    }
    <span>N/A</span>;
  };

  const tagsBodyTemplate = (rowData) => {
    if (!rowData.tags || !Array.isArray(rowData.tags)) return null;

    return (
      <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
        {rowData.tags.map((tag, index) => (
          <Chip
            key={`${tag.id}-${index}`}
            label={tag.name}
            style={{
              backgroundColor: tag.desc || "#007ad9",
              color: tag.desc === "yellow" ? "black" : "white",
              fontWeight: "bold",
              borderRadius: "20px",
              padding: "2px 15px",
            }}
          />
        ))}
      </div>
    );
  };

  const ultimaComunicacaoBodyTemplate = (rowData) => {
    if (!rowData?.devices[0]?.last_comm) return <span>N/A</span>;

    const date = new Date(rowData.devices[0].last_comm);

    const formattedDate = date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const formattedTime = date.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    return <span>{`${formattedDate} ${formattedTime}`}</span>;
  };

  const typeDeviceFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={typeDeviceOptions}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder={intl.formatMessage({ id: "manager_alarms_plus_select_alarm_type" })}
        showClear
        style={{ minWidth: "14rem" }}
      />
    );
  };

  const formatFiltersForExport = (filters) => {
    const params = {};

    const sectorFilterValue = filters['sector_name']?.constraints[0]?.value;
    if (sectorFilterValue) {
      params.sector_name = JSON.stringify({
        criteria: 'in',
        values: sectorFilterValue
      });
    }

    const serialNumberFilterValue = filters['serial_number']?.constraints[0]?.value;
    if (serialNumberFilterValue) {
      params.serial_number = JSON.stringify({
        criteria: 'in',
        values: serialNumberFilterValue
      });
    }

    const installNameFilterValue = filters['name']?.constraints[0]?.value;
    if (installNameFilterValue) {
      params.install_name = JSON.stringify({
        criteria: 'contains',
        values: installNameFilterValue,
      });
    }

    const typeFilterValue = filters['type']?.constraints[0]?.value;
    if (typeFilterValue && typeFilterValue.length > 0) {
      params.type = JSON.stringify({
        criteria: 'equals',
        values: typeFilterValue,
      });
    }

    const tagFilterValue = filters['name_tags']?.constraints[0]?.value;
    if (tagFilterValue) {
      params.tag = JSON.stringify({
        criteria: 'contains',
        values: tagFilterValue.toLowerCase(),
      });
    }

    return params;
  };

  const handleModalExportReport = () => {
    if (devices.length > 0) {
      const formattedFilters = formatFiltersForExport(filters);
      const exportConfig = {
        ...config,
        filters: formattedFilters
      };
      setConfig(exportConfig);
      setShowModalExport(true);
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Atenção",
        detail: "Não há dispositivos para exportar.",
        life: 3000,
      });
    }
  };

  const handleClose = () => {
    setShowModalExport(false);
  };

  const onRowSelect = (rowData) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowData.id)) {
        return prevSelectedRows.filter((id) => id !== rowData.id);
      } else {
        return [...prevSelectedRows, rowData.id];
      }
    });
  };

  const onSelectAll = (e) => {
    const isChecked = e.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      const allRowIds = devices.map((device) => device.id);
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows([]);
    }
  };

  const selectAllCheckboxTemplate = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Checkbox checked={selectAll} onChange={onSelectAll} />
        <StyledButton onClick={handleModalConfigDev}>
          <StyledIcon className={PrimeIcons.COG} />
        </StyledButton>
      </div>
    );
  };

  const alarmTypeFilterSerial = (options) => (
    <AlarmTypeFilterSerial
      options={options}
      devSerialOptions={devSerialOptions}
      isLoading={isLoading}
      onLazyLoad={onLazyLoad}
      setSearchSerialNumber={setSearchSerialNumber}
      isLoadingFilterMessage={loadInstallPoints}
    />
  );

  const checkboxBodyTemplate = (rowData) => {
    return (
      <Checkbox
        checked={selectedRows.includes(rowData.id)}
        onChange={(e) => {
          e.stopPropagation();
          onRowSelect(rowData);
        }}
      />
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={() => {
          options.filterApplyCallback();
          setCurrentPage(1);
        }}
        severity="success"
        style={{ backgroundColor: "#87ee97" }}
      />
    );
  };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={() => {
          options.filterClearCallback();
          setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            sector_name: {
              operator: FilterOperator.AND,
              constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },
            name: {
              operator: FilterOperator.AND,
              constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },
            serial_number: {
              operator: FilterOperator.AND,
              constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },
            type: {
              operator: FilterOperator.OR,
              constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },
            name_tags: {
              operator: FilterOperator.OR,
              constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            },
            last_comm: {
              operator: FilterOperator.AND,
              constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
            },
          });
          setDevSerialOptions([]);
          setCurrentPage(1);
          getDevices(1, rowsPerPage);
        }}
        severity="secondary"
      />
    );
  };

  const handleModalConfigDev = () => {
    const filterDevice = devices.filter((item) =>
      selectedRows.includes(item.id)
    );

    if (selectedRows.length > 0) {
      setVisible(true);
      getDeviceDetail(filterDevice);
    }

    if (selectedRows.length === 0) {
      toast.current.show({
        severity: "warn",
        summary: intl.formatMessage({ id: "title_header_install_warn_rejected" }),
        detail: intl.formatMessage({ id: "title_header_install_warn_rejected_detail" }),
        life: 3000,
      });
    }

  };

  const generatePayloadAlarms = (devices) => {
    const list = devices.map(device => ({
      install_point_id: device.id,
      device_id: device.devices[0].id,
    }));

    return {
      list,
      alarm_boundaries: {
        lower_limit: valueSlider[0],
        upper_limit: valueSlider[1],
      },
    };
  };

  const sendConfigDevices = async (data) => {
    setVisible(false);
    try {
      const filteredDevices = devices.filter((item) =>
        selectedRows.includes(item.id)
      );

      if (filteredDevices.length === 0) {
        throw new Error("Nenhum dispositivo selecionado.");
      }

      const listDevices = data.length > 0 ? data : filteredDevices;
      const payloadAlarm = generatePayloadAlarms(listDevices);

      const payloadIPEditMulti = filteredDevices.map(device => {
        const deviceTags = device.tags.map(tag => tag.id);

        const selectedTags = selectedTag.map(item => item.id_name);

        const allTags = [...new Set([...deviceTags, ...selectedTags])].join(';');

        return {
          id: device.id,
          tags: allTags
        };
      });

      const payloadIPEditOne = {
        id: filteredDevices[0].id,
        tags: selectedTag.map((item) => item.id_name).join(";"),
      };

      const promises = [meter_device_install_point_alarm_boundaries_multi(payloadAlarm)];

      if (filteredDevices.length > 1) {
        promises.push(install_points_edit_tags(payloadIPEditMulti));
      } else {
        promises.push(install_points_edit(payloadIPEditOne));
      }

      await Promise.all(promises);

      toast.current.show({
        severity: "success",
        summary: intl.formatMessage({ id: "success_summary" }),
        detail: intl.formatMessage({ id: "success_summary_detail" }),
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Falha ao salvar",
        detail: error.response.data.message,
        life: 3000,
      });
    } finally {
      setVisible(false);
      getDevices(currentPage, rowsPerPage);
    }
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value)}
        dateFormat="yy-mm-dd"
        placeholder="Selecione a Data"
        showButtonBar
      />
    );
  };

  const onSort = (e) => {
    const newSortField =
      e.sortField === "last_comm" ? "last_communication" : e.sortField;
    setSortField(newSortField);
    setSortOrder(e.sortOrder);
    getDevices(currentPage, rowsPerPage, newSortField, e.sortOrder);
  };

  const tagsFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={tagList.map(tag => ({
          label: tag.name,
          value: tag.id_name
        }))}
        onChange={(e) => {
          options.filterCallback(e.value);
        }}
        placeholder={intl.formatMessage({ id: 'title_header_install_find_tags' })}
        showClear
        filter
        emptyFilterMessage={intl.formatMessage({ id: 'empty_filter_message' })}
      />
    );
  };

  const columns = [
    {
      field: "sector_name",
      header: intl.formatMessage({ id: 'title_heat_map_sector' }),
      body: setorBodyTemplate,
      filter: {
        placeholder: intl.formatMessage({ id: 'title_header_install_find_sector' }),
        element: alarmTypeFilterSector,
      },
      showFilterMatchModes: false,
      showFilterOperator: false,
      filterMenuStyle: { width: "5rem" },
      style: { minWidth: "5rem" },
    },
    {
      field: "name",
      header: intl.formatMessage({ id: 'title_header_install_points_code' }),
      body: nameBodyTemplate,
      filter: {
        placeholder: intl.formatMessage({ id: 'title_header_install_find_code' }),
      },
      showFilterMatchModes: false,
      showFilterOperator: false,
      filterMenuStyle: { width: "14rem" },
      style: { minWidth: "14rem" },
    },
    {
      field: "serial_number",
      header: intl.formatMessage({ id: 'title_header_install_points_id_device' }),
      body: serialNumberBodyTemplate,
      filter: {
        placeholder: intl.formatMessage({ id: 'title_header_install_find_id_device' }),
        element: alarmTypeFilterSerial,
      },
      showFilterMatchModes: false,
      showFilterOperator: false,
      filterMenuStyle: { width: "14rem" },
      style: { minWidth: "14rem" },
    },
    {
      field: "type",
      header: intl.formatMessage({ id: 'title_header_install_points_device_type' }),
      body: tipoDispositivoBodyTemplate,
      filter: {
        element: typeDeviceFilterTemplate,
      },
      showFilterMatchModes: false,
      showFilterOperator: false,
      style: { minWidth: "10rem" },
    },
    {
      field: "name_tags",
      header: intl.formatMessage({ id: 'title_header_install_points_tags' }),
      body: tagsBodyTemplate,
      filter: {
        element: tagsFilterTemplate,
      },
      showFilterMatchModes: false,
      showFilterOperator: false,
      style: { minWidth: "14rem" },
    },
    {
      field: "last_comm",
      header: intl.formatMessage({ id: 'title_header_install_last_communication' }),
      body: ultimaComunicacaoBodyTemplate,
      sortable: true,
      sortField: "last_communication",
      dataType: "date",
      /* filter: {
        element: dateFilterTemplate,
      }, */
    },
  ];

  const renderColumns = () => {
    return columns.map((column, index) => (
      <Column
        key={index}
        field={column.field}
        header={column.header}
        body={column.body}
        filter={column.filter ? true : false}
        filterPlaceholder={column.filter?.placeholder}
        filterElement={column.filter?.element}
        filterClear={filterClearTemplate}
        filterApply={filterApplyTemplate}
        /*  showFilterMatchModes={column.showFilterMatchModes} */
        /*  showFilterOperator={column.showFilterOperator} */
        filterMenuStyle={column.filterMenuStyle}
        style={column.style}
        sortable={column.sortable}
        sortField={column.sortField}
        dataType={column.dataType}
        showAddButton={false}
        showFilterMatchModes={false}
        showFilterOperator={false}
      />
    ));
  };

  return (
    <Container>
      <Toast ref={toast} />
      <Header />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          marginLeft: "30px",
        }}
      >
        <Title><FormattedMessage id="install_points" /></Title>
        <button
          onClick={handleModalExportReport}
          style={{
            marginRight: "30px",
            padding: "8px 12px",
            fontSize: "16px",
            backgroundColor: "#f8f9fb",
            borderRadius: "4px",
            cursor: "pointer",
            border: "1px solid #6eff94",
          }}
        >
          <FormattedMessage id="save_reports" />
        </button>
      </div>

      <ExportReport
        onShow={showModalExport}
        onClose={handleClose}
        config={config}
      />

      <CardContainer>
        <DataTable
          value={devices}
          loading={loading}
          paginator
          lazy
          first={(currentPage - 1) * rowsPerPage}
          rows={rowsPerPage}
          onPage={onPageChange}
          totalRecords={totalRecords}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          selection={selectedDevices}
          onSelectionChange={(e) => setSelectedDevices(e.value)}
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={columns.map((col) => col.field)}
          emptyMessage={intl.formatMessage({ id: 'title_header_install_no_one_device_found' })}
          currentPageReportTemplate={intl.formatMessage({ id: 'current_pagereport_template_data_table' })}
          expandedRows={selectedDevices}
          onRowToggle={(e) => setSelectedDevices(e.data)}
          rowExpansionTemplate={(data) => (
            <RowExpansionTemplate
              data={data}
              historyCommunicationDevice={historyCommunicationDevice}
              selectedDevices={selectedDevices}
              dateStart={dateStart}
              dateEnd={dateEnd}
            />
          )}
          onRowExpand={handleRowClick}
          onFilter={(e) => {
            setFilters(e.filters);
          }}
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder}
        >
          <Column expander style={{ width: "3em" }} />
          {renderColumns()}
          <Column
            header={selectAllCheckboxTemplate}
            body={checkboxBodyTemplate}
            headerStyle={{ width: "3em" }}
          />
        </DataTable>

        <ConfigModal
          visible={visible}
          onHide={() => setVisible(false)}
          valueSlider={valueSlider}
          setValueSlider={setValueSlider}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          tagList={tagList}
          devices={devices}
          selectedRows={selectedRows}
          sendConfigDevices={sendConfigDevices}
          setVisibleModalConfirm={setVisibleModalConfirm}
        />

        <ConfirmModal
          visible={visibleModalConfirm}
          onHide={() => setVisibleModalConfirm(false)}
          devices={devices}
          selectedRows={selectedRows}
          sendConfigDevices={sendConfigDevices}
        />
      </CardContainer>
    </Container>
  );
}