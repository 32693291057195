import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import Header from "../../components/Header";
import { Container, SidebarContainer, ToggleButton, Title, Title2, Map2Container, MapContainer, Chart1, Chart2, CleanFilterButton, DropdownWrapper, DropdownLabel, ApplyFilterButton, StyledCalendar, FiltersTitleContainer, FiltersTitle, LoadingIcon, CompanyText, SectorText, TitleContainer } from './styles';
import FirstMapHomeDevices from "../../components/Maps/FirstMapHomeDevices";
import { useIntl, FormattedMessage } from 'react-intl';
import { Toast } from 'primereact/toast';
import useUserStore from '../../store/useUserStore';
import TimelapseMapPlus from '../../components/Maps/TimelapseMapPlus';
import useDateSelectedStore from '../../store/map/useDateSelectedStore';
import { flowResearchFactor, map_pins_data, mvn_data, pressure_data_list_limited_v2, scheme, sectorScheme, map_pins_data_v2 } from '../../services/dashboard';
import { listCompleteDevices, listSectors } from '../../services/install_points_plus';
import { convertDeviceAPI, convertDeviceMap, getReferenceDate, mergeDevices, timeLocationToPosition, transformArray, transformArrayFull, replicateDataWithUpdatedDates, convertDeviceAPIAdaPlus, mergeDevicesAux, transformDataMacroMeter, mergeGroups } from '../../utils/Utils';
import RenderPressure from "../../components/Charts/Pressure/index";
import RenderWaterFlow from "../../components/Charts/Flow/index";
import RenderLeakResearchFactor from "../../components/Charts/ResearchFactor/index";
import { NUMBER_DVCS_CHART_PRESSURE } from "../../config/config";
import pako from "pako";
import CustomSelectButton from '../../components/CustomSelectButton';
import LoadingModal from '../../components/LoadingModal';
import FilterComponent from '../../components/FilterHomePlus';
import _debounce from "lodash/debounce";
import useTagStore from '../../store/useTagStore';

const systemOptions = [
  { label: 'Turing', value: 'turing' },
  { label: 'ADA', value: 'ada' }
];

const endDate = new Date();
const startDate = new Date();
startDate.setDate(endDate.getDate() - 30);

let timeoutId = null;

const Dashboard = memo(() => {
  const intl = useIntl();
  const toast = useRef(null);
  const { tags } = useTagStore();
  const { user } = useUserStore();
  const [mapData, setMapData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("Todo");
  const [sectorInfo, setSectorInfo] = useState(null);
  const { dateSelected, setDateSelected } = useDateSelectedStore();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMapViewActive, setIsMapViewActive] = useState(true);
  const [devicesList, setDevicesList] = useState([]);
  const [allDevicesList, setAllDevicesList] = useState([]);
  const [sectorsList, setSectorsList] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSerialFilterDisabled, setIsSerialFilterDisabled] = useState(true);
  const [isTagFilterDisabled, setIsTagFilterDisabled] = useState(true);
  const [numberDevices, setNumberDevices] = useState(0);
  const [flowFactorData, setFlowFactorData] = useState(null);
  const [sectorNetMap, setSectorNetMap] = useState([]);
  const [sectorGeoLocation, setSectorGeoLocation] = useState([]);
  const [minMaxValueScale, setMinMaxValueScale] = useState(null);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [selectedDevicesList, setSelectedDevicesList] = useState([]);
  const [devices, setDevices] = useState([]);
  const [devicesToPressureChart, setDevicesToPressureChart] = useState([]);
  const [pressureData, setPressureData] = useState([]);
  const [referenceDatePeriod, setReferenceDatePeriod] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState('turing');
  const [selectedDates, setSelectedDates] = useState([startDate, endDate]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [locationsFrame, setLocationsFrame] = useState([]);
  const [isLoadingFrame, setIsLoadingFrame] = useState(false);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [isFramePrepared, setIsFramePrepared] = useState(false);
  const [sliderFrame, setSliderFrame] = useState(1);
  const [listMacroMeters, setListMacroMeters] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTypeSensor, setSelectedTypeSensor] = useState(null);
  const [isSameSector, setIsSameSector] = useState(true);

  const [flags, setFlags] = useState({
    showChartFlow: true,
    showChartResearchFactor: false,
    hideListTime: false,
    hideList: false,
    pressure: false,
    mvn: false,
    flow_factor: false,
    map: false,
  });

  useEffect(() => {
    debouncedInstallationPoints();
    return debouncedInstallationPoints.cancel;
  }, []);

  useEffect(() => {
    setDateSelected("30");
  }, []);

  useEffect(() => {
    const formatTag = tags.map(item => {
      return {
        id: item.id_name,
        name: item.name,
        desc: item.color
      };
    });
    setTagList(formatTag);
  }, [tags]);

  useEffect(() => {
    if (selectedTags && selectedTags.length > 0) {
      setSelectedSystem('turing');
    }
  }, [selectedTags]);

  useEffect(() => {
    if (selectedSectors?.length > 0) {
      setIsSerialFilterDisabled(false);
      setIsTagFilterDisabled(false);
    } else {
      setIsSerialFilterDisabled(true);
      setIsTagFilterDisabled(true);
    }
  }, [selectedSectors]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowLeft':
          handleDecrementDate();
          break;
        case 'ArrowRight':
          handleIncrementDate();
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentDate, sliderFrame]);

  const debouncedInstallationPoints = _debounce(() => {
    getInstallationPoints();
    getSectors();
  }, 250);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getDates = (daysDifference) => {
    const today = new Date();
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - daysDifference);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    return {
      startDate: formatDate(pastDate),
      endDate: formatDate(today)
    };
  };

  const getSectors = async () => {
    const payload = {
      per_page: 5000,
    };

    try {
      const response = await listSectors(payload);
      const data = response.items;
      const formatedData = data.map((item) => {
        return {
          sector_id: item.sector_uid,
          sector_name: item.name,
          hasMacrometer: item.hasMacrometer,
        }
      });

      setSectorsList(formatedData);
    } catch (error) {
      console.error('Error fetching sectors:', error);
    }
  };

  const getInstallationPoints = async () => {
    setIsLoading(true);
    const payloadDevices = {
      per_page: 5500,
      active: true,
    };

    try {
      const response = await listCompleteDevices(payloadDevices);
      const data = response.items;
      setDevicesList(data);

      const allDevices = data.flatMap(point =>
        point.devices.map(device => ({
          serial_number: device.serial_number,
          macrometer: device.macrometer,
          sector_name: point.sector_name,
        }))
      );
      setAllDevicesList(allDevices);
    } catch (error) {
      console.error('Error fetching installation points:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPressureData = async (devices) => {
    setFlags((prevFlags) => ({
      ...prevFlags,
      pressure: false,
      mvn: true,
      flow_factor: true,
      map: true,
    }));

    setSelectedDevicesList(devices);
    const dates = getDates(dateSelected);

    const payload = {
      dti: dates.startDate,
      dtf: dates.endDate,
      clientId: user.client_id,
      devices: devices
    };

    try {
      const response = await pressure_data_list_limited_v2(payload);
      const compressedPressureData = response.data.data;
      const decompressedPressureData = JSON.parse(
        pako.ungzip(compressedPressureData, { to: "string" })
      );
      setPressureData(decompressedPressureData);

      setFlags((prevFlags) => ({
        ...prevFlags,
        pressure: true,
        mvn: true,
        flow_factor: true,
        map: true,
      }));
    } catch (error) {
      console.error('Error fetching pressure data:', error);
    }
  };

  const fetchFlowFactorData = async (devices) => {
    const dates = getDates(dateSelected);

    const payload = {
      sectorId: devices[0].sector_id,
      dti: dates.startDate,
      dtf: dates.endDate,
      clientId: user.client_id,
    };

    try {
      const response = await flowResearchFactor(payload);
      setFlowFactorData(response.data);
      setIsMapViewActive(false);

      const ref_datetime = getReferenceDate(selectedPeriod, payload.dti);

      setReferenceDatePeriod(ref_datetime);

      setFlags((prevFlags) => ({
        ...prevFlags,
        flow_factor: true,
      }));
    } catch (error) {
      console.error('Error fetching flow factor data:', error);
    }
  };

  const prepareFrame = async (devices, data) => {
    if (isFramePrepared) return;

    const sectorIds = data
      .map(item => item.sector_id)
      .filter(sectorId => sectorId !== null);
    const allSameSectorId = sectorIds.length > 0 && sectorIds.every((sectorId, _, array) => sectorId === array[0]);
    setIsSameSector(allSameSectorId);
    const dates = getDates(dateSelected);
    const payloadSectorScheme = {
      sectorId: data[0]?.sector_id,
      dti: dates.startDate,
      dtf: dates.endDate,
      clientId: user.client_id,
    };

    try {
      if (devices) {
        const effectiveNumberDevices = numberDevices;

        if (Object.keys(devices).length > effectiveNumberDevices) {
          const dataArray = Object.values(devices);
          dataArray.sort((a, b) => {
            const pressAvgA = a.pressAvg || Number.MAX_VALUE;
            const pressAvgB = b.pressAvg || Number.MAX_VALUE;
            return pressAvgA - pressAvgB;
          });
          const listDevicesIdTop = dataArray
            .slice(0, effectiveNumberDevices)
            .map((item) => item.deviceId);
          fetchPressureData(listDevicesIdTop);
          localStorage.setItem("deviceDataInitial", JSON.stringify(listDevicesIdTop));
        }
        if (Object.keys(devices).length <= effectiveNumberDevices) {
          const listDevicesId = Object.values(devices).map(
            (item) => item.deviceId
          );
          fetchPressureData(listDevicesId);
          localStorage.setItem("deviceDataInitial", JSON.stringify(listDevicesId));
        }
      }

      const ref_datetime = getReferenceDate(selectedPeriod, dates.startDate);

      const devicesMerge = mergeDevices(devices, transformArrayFull(data));
      setMapData([devicesMerge, ref_datetime, '']);
      setDevicesToPressureChart(transformArrayFull(data));
      setPressureData([]);

      if (allSameSectorId) {
        const requests = [
          sectorScheme(payloadSectorScheme)
        ];

        let [
          sectorSchemeRes,
        ] = await Promise.all(requests);

        const { info, sectorGeoDelimitation, mainInputPressPoint, secondEntryPressurePoint, thirdEntryPressurePoint } = sectorSchemeRes.data;
        setSectorInfo(info);
        setSectorGeoLocation(sectorGeoDelimitation);

        const DeviceMainId = mainInputPressPoint;
        const DeviceSecondaryId = secondEntryPressurePoint;
        const DeviceTertiaryId = thirdEntryPressurePoint;

        setMinMaxValueScale({
          DeviceMainId,
          DeviceSecondaryId,
          DeviceTertiaryId,
        });
      }

      setFlags((prevFlags) => ({
        ...prevFlags,
        map: true,
      }));

      setIsFramePrepared(true);
    } catch (error) {
      console.error('Error preparing frame:', error);
    }
  };

  const getMacrometerDevices = (data) => {
    return data
      .filter((item) =>
        item.devices.some((device) => device.macrometer === "True")
      )
      .map((item) => ({
        serial_number: item.devices[0].serial_number,
        sector_id: item.sector_id,
      }));
  };

  const handleChartFlowToggle = useCallback(() => {
    setFlags((prevFlags) => ({
      ...prevFlags,
      showChartFlow: true,
      showChartResearchFactor: false,
    }));
  }, []);

  const handleChartResearchFactorToggle = useCallback(() => {
    setFlags((prevFlags) => ({
      ...prevFlags,
      showChartFlow: false,
      showChartResearchFactor: true,
    }));
  }, []);

  const onSelectSensor = (sensorId) => {
    setSelectedSensor((prevSelectedSensor) =>
      sensorId === prevSelectedSensor ? null : sensorId
    );
  };

  const handleSelectLine = (data) => {
    onSelectSensor(data.label);
    setSelectedSensor(data.label);
  };

  const handleFetchPressureData = (data) => {
    const arrayDvcs = Object.keys(data).filter(key => data[key]);
    const length = arrayDvcs.length;
    setNumberDevices(length > 0 ? parseInt(NUMBER_DVCS_CHART_PRESSURE) : 0);
    if (length > parseInt(NUMBER_DVCS_CHART_PRESSURE)) {
      toast.current.show({
        severity: "error",
        summary: intl.formatMessage({ id: "error_attention" }),
        detail: intl.formatMessage({ id: "msg_number_devices_to_get" }),
        life: 5000,
      });
      return;
    }
    fetchPressureData(arrayDvcs);
  };

  const handleFilteredData = (data) => {
    resetStates();
    setFilteredDevices(data);
    setIsMapViewActive(false);

    if (data && data.length > 0) {
      loadData(currentDate, data);
      if (getMacrometerDevices(data) && getMacrometerDevices(data).length > 0) {
        setListMacroMeters(getMacrometerDevices(data));
        fetchFlowFactorData(getMacrometerDevices(data));
      }
    } else {
      setFlags((prevFlags) => ({
        ...prevFlags,
        pressure: true,
        flow_factor: true,
        map: true,
      }));
    }
  };

  const handleIsLoading = (data) => {
    if (data === false) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        setIsLoading(false);
        timeoutId = null;
      }, 100);
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      setIsLoading(true);
    }
  };

  useEffect(() => {
    loadData(currentDate, filteredDevices);
  }, [currentDate]);

  function formatDate(dateInput) {
    const date = new Date(dateInput);
    return date.toISOString().split("T")[0];
  }

  const loadData = async (date, data) => {
    const serialNumbers = data.flatMap(item =>
      item.devices.map(device => device.serial_number)
    );

    const payload = {
      devices: serialNumbers,
      dti: formatDate(date),
      dtf: formatDate(date),
      clientId: user.client_id,
    };
    setIsLoadingFrame(true);
    try {
      const response = await map_pins_data_v2(payload);
      const macroForm = transformDataMacroMeter(data);
      const auxMapPins = convertDeviceAPIAdaPlus(response.data);
      const devices = convertDeviceMap(mergeGroups(auxMapPins, macroForm));
      const ref_datetime = getReferenceDate(selectedPeriod, payload.startDate);
      const { positions, locations } = timeLocationToPosition(
        response.data,
        devices,
        ref_datetime
      );

      if (data && data[0]) {
        if (!isFramePrepared) {
          prepareFrame(devices, data);
        }
      }
      setLocationsFrame(devices);
    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      setIsLoadingFrame(false);
    }
  };

  const handleSliderChange = (newDate) => {
    setCurrentDate(newDate);
  };

  const handleDecrementDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 1);
    if (sliderFrame < parseInt(dateSelected)) {
      setSliderFrame(sliderFrame + 1);
    }

    const today = new Date();
    const minDate = new Date(today);
    minDate.setDate(today.getDate() - parseInt(dateSelected));

    if (newDate >= minDate) {
      setCurrentDate(newDate);
    } else {
      console.warn('Cannot go further back than 30 days.');
    }
  };

  const handleIncrementDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 1);

    if (sliderFrame > 1) {
      setSliderFrame(sliderFrame - 1);
    }

    const today = new Date();
    if (newDate <= today) {
      setCurrentDate(newDate);
    } else {
      console.warn('Cannot go beyond today.');
    }
  };

  const handleClearFiltesSelecteds = () => {
    setIsMapViewActive(true);
    setSelectedSector(null);
    setSelectedDevices([]);
    setSelectedTags([]);
    setSelectedDates([startDate, endDate]);
    setSelectedTypeSensor(null);
  };

  const resetStates = () => {
    setMapData([]);
    setSectorInfo(null);
    setDateSelected("30");
    setIsSidebarOpen(true);
    setIsMapViewActive(true);
    // setSectorsList([]);
    setSelectedSectors(null);
    setIsLoading(false);
    setIsSerialFilterDisabled(true);
    setIsTagFilterDisabled(true);
    setNumberDevices(0);
    setFlowFactorData(null);
    setSectorNetMap([]);
    setSectorGeoLocation([]);
    setMinMaxValueScale(null);
    setSelectedSensor(null);
    setSelectedDevicesList([]);
    setDevicesToPressureChart([]);
    setPressureData([]);
    setReferenceDatePeriod(null);
    setSelectedSystem('turing');
    setSelectedDates([startDate, endDate]);
    setCurrentDate(new Date());
    setLocationsFrame([]);
    setIsLoadingFrame(false);
    setFilteredDevices([]);
    setIsFramePrepared(false);
    setSliderFrame(1);
    setListMacroMeters([]);
    setFlags({
      showChartFlow: true,
      showChartResearchFactor: false,
      hideListTime: false,
      hideList: false,
      pressure: false,
      mvn: false,
      flow_factor: false,
      map: false,
    });
  };

  return (
    <>
      <LoadingModal loading={isLoading} />
      <Toast ref={toast} />
      <Header hideAlarm={isMapViewActive} />
      <Container $isSidebarVisible={isSidebarOpen} $isFirstOptionSelected={isMapViewActive}>

        <SidebarContainer>
          <ToggleButton onClick={toggleSidebar}>
            {isSidebarOpen ? '<' : '>'}
          </ToggleButton>

          {isSidebarOpen && (
            <FilterComponent
              onFilter={handleFilteredData}
              hideTimerFilter={isMapViewActive}
              onIsLoading={handleIsLoading}
              onClearFilter={handleClearFiltesSelecteds}
              installPointsList={devicesList}
              sectorsList={sectorsList}
              devicesList={allDevicesList}
              tagList={tagList}
              selectedSector={selectedSector}
              setSelectedSector={setSelectedSector}
              selectedDevices={selectedDevices}
              setSelectedDevices={setSelectedDevices}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              selectedTypeSensor={selectedTypeSensor}
              setSelectedTypeSensor={setSelectedTypeSensor}
              devices={devices}
              setDevices={setDevices}
            />
          )}
        </SidebarContainer>

        <Title>
          <TitleContainer $isActive={selectedSector}>
            <CompanyText>{user?.client?.trading_name || 'N/A'}</CompanyText>
            <SectorText>
              {selectedSector?.sector_name || ''}
            </SectorText>
          </TitleContainer>
        </Title>

        {!isMapViewActive && (
          <Title2 $isFirstOptionSelected={isMapViewActive}>
            <CustomSelectButton
              options={systemOptions}
              value={selectedSystem}
              onChange={setSelectedSystem}
              disabled={false}
              disabledOptions={selectedSector && isSameSector ? [] : ['ada']}
            />
          </Title2>
        )}

        {isMapViewActive && <Map2Container $isFirstOptionSelected={isMapViewActive}>
          <FirstMapHomeDevices points={devicesList} />
        </Map2Container>}

        {!isMapViewActive && flags.map &&
          <MapContainer $isFirstOptionSelected={isMapViewActive} $skeleton={flags.map}>
            {flags.map && <TimelapseMapPlus
              key={JSON.stringify(mapData[0])}
              period={currentDate}
              devices={mapData && mapData.length > 0 ? mapData[0] : []}
              locations={locationsFrame}
              layers={null}
              traces={sectorNetMap}
              geoLocation={sectorGeoLocation}
              onSelectSensor={onSelectSensor}
              selectedSensor={selectedSensor}
              info={sectorInfo}
              userType={user?.type_user}
              isMaster={true}
              minMaxValueScale={minMaxValueScale}
              adaPlus={true}
              onGetDraw={handleFetchPressureData}
              isTuring={selectedSystem === 'turing'}
              onSliderDate={handleSliderChange}
              isLoading={isLoadingFrame}
              onDecrementDate={handleDecrementDate}
              onIncrementDate={handleIncrementDate}
              sliderFrame={sliderFrame}
            />}
          </MapContainer>}

        {!isMapViewActive && (
          <Chart1 $isFirstOptionSelected={isMapViewActive} $skeleton={!flags.pressure}>
            {flags.pressure && (
              <RenderPressure
                ref_date={mapData && mapData.length > 0 ? mapData[1] : []}
                region={pressureData}
                devicesList={devicesToPressureChart}
                selectedSensor={selectedSensor}
                onSelectLine={handleSelectLine}
                onGetDevices={handleFetchPressureData}
                initialDevicesList={selectedDevicesList}
                adaPlus={true}
              />
            )}
          </Chart1>
        )}

        {!isMapViewActive && (
          <Chart2 $isFirstOptionSelected={isMapViewActive} $skeleton={!flags.flow_factor}>
            {flags.showChartFlow && flags.flow_factor && (
              <RenderWaterFlow
                ref_date={referenceDatePeriod}
                region={flowFactorData}
                btnChangeChart={handleChartResearchFactorToggle}
                listMacro={listMacroMeters}
                onSelectMacroMeter={fetchFlowFactorData}
              />
            )}
            {flags.showChartResearchFactor && flags.flow_factor && (
              <RenderLeakResearchFactor
                ref_date={referenceDatePeriod}
                region={flowFactorData}
                btnChangeChart={handleChartFlowToggle}
                listMacro={listMacroMeters}
                onSelectMacroMeter={fetchFlowFactorData}
              />
            )}
          </Chart2>
        )}
      </Container>
    </>
  );
});

export default Dashboard;