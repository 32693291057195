import api from "./api_stg";
import { default as api_turing } from "./api";

const userData = JSON.parse(sessionStorage.getItem("@ada-user"));

export const listCompleteDevices = async ({
  page,
  per_page,
  sector_name,
  install_name,
  serial_number,
  type,
  tag,
  active,
  order_by,
  alarmed,
  metadata,
  name,
  xFields,
}) => {
  try {
    const response = await api.get("/devices/install_points", {
      params: {
        page,
        per_page,
        sector_name,
        install_name,
        serial_number,
        type,
        tag,
        active,
        order_by,
        alarmed,
        metadata,
        name,
      },
      headers: {
        ...(xFields ? { "X-Fields": xFields } : {}),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const listEvents = async ({
  page,
  per_page,
  sector_name,
  install_name,
  serial_number,
  type,
  tag,
  active,
  order_by,
  xFields,
  metadata,
  to,
  from
}) => {
  try {
    const response = await api.get("devices/events", {
      params: {
        page,
        per_page,
        sector_name,
        install_name,
        serial_number,
        type,
        tag,
        active,
        order_by,
        metadata,
        to,
        from
      },
      headers: {
        ...(xFields ? { "X-Fields": xFields } : {}),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const listDevicesLite = async ({
  page,
  per_page,
  sector_name,
  install_name,
  serial_number,
  type,
  tag,
  active,
  xFields,
}) => {
  try {
    const response = await api.get("/devices/install_points_lite", {
      params: {
        page,
        per_page,
        sector_name,
        install_name,
        serial_number,
        type,
        tag,
        active,
      },
      headers: {
        ...(xFields ? { "X-Fields": xFields } : {}),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar os pontos de instalação:", error);
    throw error;
  }
};

export const listSectorsAlarmeds = async ({
  page,
  per_page,
  only_with_alarms,
  xFields,
}) => {
  try {
    const response = await api.get("/devices/sectors", {
      params: {
        page,
        per_page,
        only_with_alarms,
      },
      headers: {
        ...(xFields ? { "X-Fields": xFields } : {}),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar os pontos de instalação:", error);
    throw error;
  }
};

export const listSectors = async ({
  page,
  per_page,
  xFields,
}) => {
  try {
    const response = await api.get("/devices/sectors", {
      params: {
        page,
        per_page,
      },
      headers: {
        ...(xFields ? { "X-Fields": xFields } : {}),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar os pontos de instalação:", error);
    throw error;
  }
};


export const communication_history_device = (data) => {
  return api_turing.post(
    "/connectivity_data/filter/communication_history",
    data
  );
};

export const device_detail = (data) => {
  return api_turing.post("/meter_device_install_point/details", data);
};

export const tags_create = (data) => {
  return api_turing.post("/tags", data);
};

export const tags_filter = (data) => {
  return api_turing.post("/tags/filter", data);
};

export const meter_device_install_point = (data) => {
  return api_turing.patch("/meter_device_install_point", data);
};

export const meter_device_install_point_alarm_boundaries_multi = (data) => {
  return api_turing.patch("/meter_device_install_point/bulk/alarm-boundaries", data);
};

export const install_points_edit = (data) => {
  return api_turing.patch("/install_points", data);
};

export const install_points_edit_tags = (data) => {
  return api_turing.patch("/install_points/bulk/tags", data);
};

export const tags_delete = (tagData) => {
  const { token } = userData.state.user;

  if (!token) {
    throw new Error("Token de autenticação não encontrado.");
  }

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return api_turing.delete("/tags", {
    headers,
    data: tagData,
  });
};
